import React from "react"
import { Link, graphql } from "gatsby"
import { SEO } from "../components/Seo"
import { rhythm, scale } from "../utils/typography"
import { Header } from "../components/Header"
import { EmailSignup } from '../components/EmailSignup'
import { DiabeticRed } from "../utils/colors"
import theme from "../utils/theme"
import { useMediaQuery } from "react-responsive"
import { Footer } from "../components/Footer"
import ColorHash from 'color-hash'

const BodyFontFamily = theme.bodyFontFamily
    .map((font, i) =>
        i !== theme.bodyFontFamily.length - 1 ? `'${font}'` : font
    )
    .join(", ")

function Card(props) {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const Required = [
        "slug",
        "title",
        "subtitle",
        "description",
        "tags",
    ]
    for (const field of Required) {
        if (!(field in props)) {
            throw new Error(`Missing "${field}" in props.`)
        }
    }

    return (
        <Link
            className="Card"
            to={props.slug}
            style={{
                padding: 0,
                display: "block",
                borderWidth: 1,
                borderStyle: "solid",
            }}
        >
            {/*
      <PreviewImage previewImage={props.previewImage} />
      */}
            <div style={{
                paddingLeft: isMobile ? rhythm(1 / 2) : rhythm(1),
                paddingRight: isMobile ? rhythm(1 / 2) : rhythm(1),
                paddingTop: rhythm(1),
                paddingBottom: rhythm(1),
            }}>
                <div style={{
                    fontStyle: 'italic',
                    backgroundColor: new ColorHash({ lightness: .9, saturation: .5 }).hex(props.tags),
                    color: 'black',
                    borderRadius: 16,
                    paddingLeft: 8,
                    paddingRight: 8,
                    marginBottom: rhythm(1 / 2),
                    display: 'inline-block',
                }}>
                    {'#' + props.tags}
                </div>
                <h2
                    style={{
                        color: DiabeticRed,
                        marginTop: 0,
                        marginBottom: rhythm(1 / 4),
                    }}
                >
                    {props.title}
                </h2>
                <h3
                    style={{
                        fontStyle: "italic",
                        fontWeight: 300,
                        fontFamily: BodyFontFamily,
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 0,
                        marginBottom: rhythm(1),
                        ...scale(1 / 4),
                    }}
                >
                    {props.subtitle}
                </h3>
                {false &&
                    <p style={{ color: "black", marginTop: 0, marginBottom: rhythm(1) }}>
                        {props.description}
                    </p>
                }
                <div className="CallToAction" style={{ color: DiabeticRed }}>
                    Read more ⟶
                </div>
            </div>
        </Link>
    )
}

function Homepage(props) {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const { growthNodes } = props

    return (
        <main
            style={{
                margin: "0 auto",
                paddingRight: rhythm(1 / 2),
                paddingLeft: rhythm(1 / 2),
                maxWidth: 600,
            }}
        >
<EmailSignup
	header="The High-Impact Developer"
	copy={<>Want to have more business impact in your job, and do your best work?<br /><br />Sign up for my email newsletter, and I'll send you a value-packed email whenever I publish a new post about <a href="/impact" target="_blank"><em>#impact</em></a>.</>}
	altColor
	urlPath="/impact"
/>

<h2 style={{ fontFamily: BodyFontFamily, textAlign: 'center', fontWeight: 100, fontStyle: 'italic' }}>Or view past content below ↓</h2>

            <ul
                style={{
                    listStyle: "none",
                    margin: 0,
                }}
            >
                {growthNodes.filter(node => (node.frontmatter.tags || '').indexOf('impact') > -1).map(node => (
                    <li
                        style={{
                            marginBottom: isMobile ? rhythm(1 / 2) : rhythm(1),
                        }}
                        key={node.id}
                    >
                        <Card
                            title={node.frontmatter.title}
                            subtitle={node.frontmatter.subtitle}
                            slug={node.fields.slug}
                            emojiClassName={node.frontmatter.emojiClassName}
                            description={node.frontmatter.description}
                            previewImage={node.frontmatter.previewImageUrl}
                            tags={node.frontmatter.tags}
                        />
                    </li>
                ))}
            </ul>


        </main>
    )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { contentType: { ne: "portfolio" } } }) {

      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            description
            previewImageUrl {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default function Page({ data }) {
    const { site } = data
    const growthNodes = data.allMdx.edges.map(edge => edge.node)
    // const { fluid } = data.file.childImageSharp
    console.log('growth nodes:', growthNodes)

    // See gatsby-config.js for actual values.
    const { title, description } = site.siteMetadata

    return (
        <>
            <SEO // This guy has a componentWillMount deprecation.
                description={description}
                imageUrl="https://user-images.githubusercontent.com/914228/73634336-d92bb800-4615-11ea-9739-5982b7a8a02e.png"
                imageAlt={title}
                title=""
            />
            <Header />
            <Homepage growthNodes={growthNodes} /*previewImage={fluid}*/ />
            <Footer />
        </>
    )
}
